import { Badge, Card, Carousel, Button, Dropdown } from "flowbite-react";

function Home() {
  return (
    <div className="container mx-auto px-4 gap-2 grid md:grid-cols-2 sm:grid-cols-1">
      <Card className="w-full">
        <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
          Account Details
        </h5>
        <h6 className="text-md font-bold">Phone Number</h6>
        <p className="font-normal text-grey-700 dark:text-gray-400 pl-4">
          +1 (208) 585-1588
        </p>
        <h6 className="text-md font-bold">Email Address</h6>
        <p className="font-normal text-grey-700 dark:text-gray-400 pl-4">
          adamclerk@gmail.com
        </p>
        <Button color="blue">Edit Details</Button>
      </Card>
      <Card className="w-full">
        <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
          Notification Settings
        </h5>
        <h6 className="text-md font-bold">Remind me by txt message every:</h6>
        <Dropdown color="blue" label="Remind me to pray every">
            <Dropdown.Item>Every Day</Dropdown.Item>
            <Dropdown.Item>Once a Week</Dropdown.Item>

        </Dropdown>
      </Card>
    </div>
  );
}
export default Home;
