import { useState, useContext, useEffect } from "react"
import { AuthContext } from "../authContext"
import { Link, Navigate, useNavigate } from "react-router-dom"
import { TextInput, Label } from 'flowbite-react'
import { cognitoConfig } from "../cognitoConfig";
import { CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";

const NOTSIGNEDIN = 'NOTSIGNEDIN';
const WAITINGFOROTP = 'WAITINGFOROTP';
const SIGNUP = 'SIGNUP';


export default function Login() {
  const [cognitoUser, setCognitoUser] = useState(null)
  const [otp, setOtp] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [formState, setFormState] = useState(NOTSIGNEDIN)
  const [error, setError] = useState("")
  const { user, signIn, verifyOtp } = useContext(AuthContext)
  const navigate = useNavigate();

  const internationalizePhoneNumber = (phoneNumber) => {
    return `+1` + phoneNumber;
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError("")

    try {
      setFormState(WAITINGFOROTP)
      const intPhone = internationalizePhoneNumber(phoneNumber);
      const userPool = new CognitoUserPool({
        UserPoolId: cognitoConfig.UserPoolId,
        ClientId: cognitoConfig.ClientId,
      });
      const user = new CognitoUser({
        Username: intPhone,
        Pool: userPool,
      });
      setCognitoUser(user);
      await signIn(user, phoneNumber)
    } catch (err) {
      setError(err.message)
    }
  }

  const handleVerifyOtp = async (e) => {
    e.preventDefault()
    setError("")

    try {
      await verifyOtp(cognitoUser, otp)
      navigate('/app');
    } catch (err) {
      setError(err.message)
    }
  }

  // If the user is logged in, don't show the login form
  if (user) {
    // Redirect to the profile page
    return <Navigate to="/profile" />
  }

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          {formState === NOTSIGNEDIN && (
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Sign in to your account
            </h1>
            <form class="space-y-4 md:space-y-6" action="#" onSubmit={handleSubmit}>
              <div className="flex items-center">
                <div id="dropdown-phone-button"
                  class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
                  type="button"
                >
                +1
                </div>
                  <div class="relative w-full">
                    <TextInput
                      onChange={(event) => setPhoneNumber(event.target.value)}
                      type="text"
                      id="phone-input"
                      class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                      pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                      placeholder="1234567890"
                      required
                    />
                  </div>
              </div>
              <button
                type="submit"
                class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Get One Time Password
              </button>
              <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                Don’t have an account yet?{" "}
                <a
                  href="#"
                  class="font-medium text-primary-600 hover:underline dark:text-primary-500"
                  onClick={() => setFormState(SIGNUP)}
                >
                  Sign up
                </a>
              </p>
            </form>
          </div>
          )}
          {formState === WAITINGFOROTP && (
            <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Please provide your Code
            </h1>
            <form class="space-y-4 md:space-y-6" action="#" onSubmit={handleVerifyOtp}>
              <div>
                <Label
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Sent to +1{phoneNumber}
                </Label>
                <TextInput
                  name="phone"
                  id="phone"
                  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={(event) => setOtp(event.target.value)}
                  pattern="[0-9]{6}"
                  value={otp}
                />
              </div>
              <button
                type="submit"
                class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Submit Code
              </button>
              <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                Don’t have an account yet?{" "}
                <a
                  href="#"
                  class="font-medium text-primary-600 hover:underline dark:text-primary-500"
                >
                  Sign up
                </a>
              </p>
            </form>
          </div>
          )}
        </div>
      </div>
    </section>
  )
}
