import {
  BrowserRouter,
  Route,
  Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import SignUp from "./pages/signup";
import ConfirmSignUp from "./pages/confirmSignup";
import Login from "./pages/login";
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from "./pages/resetPassword";
import AppHome from "./pages/app";
import Settings from "./pages/settings";
import Prayers from "./pages/prayers";
import Organizations from "./pages/organizations";
import Splash from "./pages/splash";
import { AuthContext, AuthProvider } from "./authContext";
import RouteGuard from "./RouteGuard";
import { useContext } from "react";
import { Navbar } from "flowbite-react";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
const queryClient = new QueryClient()

function Navigation() {
  const navigate = useNavigate();
  const { user, signOut } = useContext(AuthContext);

  const signOutUser = () => {
    signOut();
    navigate("/");
  };

  if (user === null) {
    return (
    <Navbar fluid className="border-b-2 border-gray-400">
      <Navbar.Brand as={Link} to="/">
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
          Guided Prayer
        </span>
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Navbar.Link as={Link} to="/">
          Home
        </Navbar.Link>
        <Navbar.Link as={Link} to="/signup">
          Signup
        </Navbar.Link>
        <Navbar.Link as={Link} to="/login">
          Login
        </Navbar.Link>
      </Navbar.Collapse>
    </Navbar>
    )
  }

  return (
    <Navbar fluid className="border-b-2 border-gray-400">
      <Navbar.Brand as={Link} href="https://flowbite-react.com">
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
          Guided Prayer
        </span>
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Navbar.Link as={Link} to="/app">
          Home
        </Navbar.Link>
        <Navbar.Link as={Link} to="/prayers">
          Prayers
        </Navbar.Link>
        <Navbar.Link as={Link} to="/organizations">
          Organization
        </Navbar.Link>

        <Navbar.Link as={Link} to="/settings">
          Settings
        </Navbar.Link>
        <li>
          <a className="block py-2 pr-4 pl-3 md:p-0 border-b border-gray-100 text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:border-0 md:hover:bg-transparent md:hover:text-cyan-700 md:dark:hover:bg-transparent md:dark:hover:text-white" href="#" onClick={signOutUser}>
            Sign Out
          </a>
        </li>
      </Navbar.Collapse>
    </Navbar>
  );
}

function App() {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Navigation />
        <main>
          <Routes>
            <Route
              path="/app"
              element={
                <RouteGuard>
                  <AppHome />
                </RouteGuard>
              }
            />
            <Route
              path="/prayers"
              element={
                <RouteGuard>
                  <Prayers />
                </RouteGuard>
              }
            />
            <Route
              path="/settings"
              element={
                <RouteGuard>
                  <Settings />
                </RouteGuard>
              }
            />

            <Route
              path="/organizations"
              element={
                <RouteGuard>
                  <Organizations />
                </RouteGuard>
              }
            />
            <Route path="/" element={<Splash/>} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/confirm-sign-up" element={<ConfirmSignUp />} />
            {/* <Route
              path="/profile"
              element={
                <RouteGuard>
                  <Profile />
                </RouteGuard>
              }
            /> */}
            {/* Add other routes here */}
          </Routes>
        </main>
      </BrowserRouter>
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default App;
