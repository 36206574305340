import { TypedDocumentNode } from "@graphql-typed-document-node/core"
import request, { Variables, RequestDocument } from "graphql-request"
import { VariablesAndRequestHeadersArgs } from "graphql-request/build/esm/types"
import { useContext } from "react"

export const authQuery = async <T, V>(
  session: any,
  query:RequestDocument | TypedDocumentNode<T, V> ,
  variables: Variables): Promise<T> => {
  return request(
    'https://infomuxkrkpiafhjnmch.hasura.us-east-1.nhost.run/v1/graphql',
    query,
    variables,
    {
      "Authorization": `Bearer ${session.idToken.jwtToken}`
    }
  )
}
