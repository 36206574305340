import { createContext, useState, useEffect } from "react"
import * as auth from "./auth"

const AuthContext = createContext()

function AuthProvider({ children }) {
  const [user, setUser] = useState(null)
  const [session, setSession] = useState(null) // [NEW
  const [isLoading, setIsLoading] = useState(true)

  const getCurrentUser = async () => {
    try {
      const user = await auth.getCurrentUser()
      const session = await auth.getSession();
      setSession(session);
      setUser(user)
    } catch (err) {
      // not logged in
      auth.signOut();
      setUser(null)
      setSession(null)
    }
  }

  useEffect(() => {
    getCurrentUser()
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false))
  }, [])

  const signIn = async (cognitoUser, phoneNumber) => {
    await auth.signIn(cognitoUser, phoneNumber)
  }

  const signOut = async () => {
    await auth.signOut()
    setUser(null)
    setSession(null);
  }

  const verifyOtp = async (cognitoUser, otp) => {
    await auth.verifyOtp(cognitoUser, otp)
    await getCurrentUser()
  }

  const authValue = {
    user,
    session,
    isLoading,
    signIn,
    signOut,
    verifyOtp
  }

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  )
}

export { AuthProvider, AuthContext }
