import { Badge, Card, Carousel, Button } from "flowbite-react";
import { Link } from "react-router-dom";

function Splash() {
  return (
    <div className="container mx-auto px-4 pt-4">
      <div className="h-56 sm:h-64 xl:h-80 2xl:h-96">
        <Carousel>
          <Card className="max-w h-full bg-gray-200 px-12">
            <h2 className="text-3xl">Stay Connected</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </p>
          </Card>
          <Card className="max-w h-full bg-gray-200 px-12">
            <h2 className="text-3xl">Daily or Weekly Reminders</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </p>
          </Card>
          <Card className="max-w h-full bg-gray-200 px-12">
            <h2 className="text-3xl">As a Podcast or as a TXT message.</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </p>
          </Card>
          {/*<div className="flex h-full items-center justify-center bg-gray-200 dark:bg-gray-300 dark:text-white">
            Slide 3
          </div> */}
        </Carousel>
      </div>
      <h2 className="my-5 text-2xl">Benefits of Prayer</h2>
      <div class="container mx-auto px-4 gap-2 grid md:grid-cols-2 sm:grid-cols-1">
        <Card className="max-w-sm">
          <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            Connect with God
          </h5>
          <p className="font-normal text-grey-700 dark:text-gray-400">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas
            saepe quibusdam ut ab id nesciunt totam voluptatum necessitatibus
            facilis amet enim consequuntur ipsam placeat, deserunt sit officia
            tenetur iure obcaecati.
          </p>
        </Card>
        <Card className="max-w-sm">
          <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            Reduce Stress and Anxiety
          </h5>
          <p className="font-normal text-grey-700 dark:text-gray-400">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas
            saepe quibusdam ut ab id nesciunt totam voluptatum necessitatibus
            facilis amet enim consequuntur ipsam placeat, deserunt sit officia
            tenetur iure obcaecati.
          </p>
        </Card>
        <Card className="max-w-sm">
          <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            Connect with Others
          </h5>
          <p className="font-normal text-grey-700 dark:text-gray-400">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas
            saepe quibusdam ut ab id nesciunt totam voluptatum necessitatibus
            facilis amet enim consequuntur ipsam placeat, deserunt sit officia
            tenetur iure obcaecati.
          </p>
        </Card>
        <Card className="max-w-sm">
          <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            Cultivate Gratitude
          </h5>
          <p className="font-normal text-grey-700 dark:text-gray-400">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas
            saepe quibusdam ut ab id nesciunt totam voluptatum necessitatibus
            facilis amet enim consequuntur ipsam placeat, deserunt sit officia
            tenetur iure obcaecati.
          </p>
        </Card>
      </div>
      <div class="bg-white">
        <div class="mx-auto max-w-7xl py-10">
          <div class="relative isolate overflow-hidden bg-gray-200 px-6 pt-16 shadow-2xl sm:rounded-lg sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
            {/* <svg viewBox="0 0 1024 1024" class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0" aria-hidden="true">
        <circle cx="512" cy="512" r="512" fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fill-opacity="0.7" />
        <defs>
          <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
            <stop stop-color="#7775D6" />
            <stop offset="1" stop-color="#E935C1" />
          </radialGradient>
        </defs>
      </svg> */}
            <div class="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
              <h2 class="text-3xl font-bold tracking-tight text-grey-700 sm:text-4xl">
                Use Guided Prayer.
                <br />
                Start praying more today.
              </h2>
              <p class="mt-6 text-lg leading-8 text-gray-700">
                Ac euismod vel sit maecenas id pellentesque eu sed consectetur.
                Malesuada adipiscing sagittis vel nulla.
              </p>
              <div class="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                <Link
                  href="/login"
                  class="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                >
                  Get started
                </Link>
                <a
                  href="#"
                  class="text-sm font-semibold leading-6 text-grey-700"
                >
                  Learn more <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
            <div class="relative mt-16 h-80 lg:mt-8">
              <img
                class="absolute left-0 top-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
                src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
                alt="App screenshot"
                width="1824"
                height="1080"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Splash;
