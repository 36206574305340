import {useQuery} from '@tanstack/react-query';
import { Badge, Button } from "flowbite-react";
import {graphql} from '../gql'
import { useContext } from 'react';
import { AuthContext } from '../authContext';
import {authQuery} from '../services/graphql';
import { getTimeSince, sortedByDate } from '../services/date';

const userStats = graphql(/* GraphQL */`
  query userStats($id: uuid!) {
    user(id: $id) {
      id
      displayName
      email
      phoneNumber
      prayers {
        title
        description
        created_on
        user {
          displayName
        }
        tags
      }
    }
  }
`)

function Home() {
  const { user, session } = useContext(AuthContext)
  const statsQuery = useQuery({
    queryKey: ['userStats'],
    queryFn: async() => {
      const data = await authQuery(session, userStats, {id: user?.sub});
      return data
    }
  })

  if (statsQuery.isLoading) return <div>Loading...</div>
  if (statsQuery.isError) return <div>Error</div>

  return (
    <div className="container mx-auto px-4">
      <h2 className="my-5 text-2xl">Summary for {statsQuery.data?.user?.displayName}</h2>
      {/* <div className="h-56 sm:h-64 xl:h-80 2xl:h-96">
        <Carousel>
            <Card className="max-w h-full bg-gray-200 px-12">
              <h2 className="text-3xl">26 Days... Congratulations!!!</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </p>
            </Card>
            <Card className="max-w h-full bg-gray-200 px-12">
              <h2 className="text-3xl">300 Payers</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </p>
            </Card>
            <Card className="max-w h-full bg-gray-200 px-12">
              <h2 className="text-3xl">60 People Praying</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </p>
            </Card>
        </Carousel>
      </div> */}
      <h2 className="my-5 text-2xl">New Prayers</h2>
      <div className="flex flex-wrap gap-2">
        { statsQuery.data?.user?.prayers.sort(sortedByDate).map((prayer: any) => {
          return (
            <a href="#" className="w-80 gap-2 pt-10 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <h5 className="h-14 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                {prayer.title}
              </h5>
              <h6 className="text-right pb-2">{getTimeSince(prayer.created_on)}</h6>
              <div className="flex flex-wrap gap-2 justify-end">
                 {prayer.tags.map((tag: string, index: number) => {
                   return <Badge color="green" key={index}>{tag}</Badge>
                   }
                 )}
               </div>
            </a>
          )
        })}
      </div>
      <h2 className="my-5 text-2xl">Take Action</h2>
      <div className="flex gap-2 my-10">
        <Button color="blue" size="xl">Listen and Pray Now</Button>
        <Button color="blue" size="xl">Add a new Prayer</Button>
      </div>
    </div>
  );
}
export default Home;
