
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

export const getTimeSince = (date: string) => {
  TimeAgo.addLocale(en)
  const timeAgo = new TimeAgo('en-US');
  const offset = (new Date()).getTimezoneOffset() * 60000;
  const adjustedDate: Date = new Date((new Date(date) as any - offset));
  return timeAgo.format(adjustedDate);
}

export const sortedByDate = (prayerA: any, prayerB: any) => {
  return new Date(prayerB.createdOn).getTime() - new Date(prayerA.createdOn).getTime()
}
