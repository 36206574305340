import { useEffect, useState } from "react"
import { signUp } from "../auth"
import { TextInput } from "flowbite-react"


export default function SignUp() {
  const [phoneNumber, setPhoneNumber] = useState("")
  const [internationalPhoneNumber, setInternationalPhoneNumber] = useState("")
  const [email, setEmail] = useState("")
  const [firstName, setFirstName] = useState("")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const internationalizePhoneNumber = (phoneNumber) => {
    return `+1` + phoneNumber;
  }

  useEffect(() => {
    if (phoneNumber[0] !== "+") {
      setInternationalPhoneNumber(internationalizePhoneNumber(phoneNumber))
    }
  }, [phoneNumber])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError("")

    try {
      await signUp(internationalPhoneNumber, email, firstName)
      setSuccess(true)
    } catch (err) {
      setError(err.message)
    }
  }

  if (success) {
    return (
      <div>
        <h2>SignUp successful!</h2>
        <p>Please check your email for the confirmation code.</p>
      </div>
    )
  }

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Sign up for an Account
            </h1>
            <form class="space-y-4 md:space-y-6" action="#" onSubmit={handleSubmit}>
              <div className="flex items-center">
                <div id="dropdown-phone-button"
                  class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
                  type="button"
                >
                +1
                </div>
                  <div class="relative w-full">
                    <TextInput
                      onChange={(event) => setPhoneNumber(event.target.value)}
                      type="text"
                      id="phone-input"
                      class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                      pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                      placeholder="1234567890"
                      required
                    />
                  </div>
              </div>
              <div className="flex items-center">
                <div class="relative w-full">
                  <TextInput
                    onChange={(event) => setEmail(event.target.value)}
                    type="email"
                    id="email-input"
                    class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                    placeholder="Email"
                    required
                  />
                </div>
              </div>
              <div class="flex items-center">
                <div class="relative w-full">
                  <TextInput
                    onChange={(event) => setFirstName(event.target.value)}
                    type="text"
                    id="first-name-input"
                    class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                    placeholder="First Name"
                    required
                  />
                </div>
              </div>
              <button
                type="submit"
                class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Sign Up
              </button>
              <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                Already have an account? &nbsp;
                <a
                  href="#"
                  class="font-medium text-primary-600 hover:underline dark:text-primary-500"
                >
                  Login
                </a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}
